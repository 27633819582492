import React from 'react';
import { Link } from 'gatsby';

import illu from '../images/404.svg';

export default function Example() {
  return (
    <div className=" h-screen">
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="flex space-x-8 items-center">
            <div className="m-auto">
              <img src={illu} className="w-72" alt="404" />
            </div>
            <div className="sm:flex">
              <div className="">
                <div className="">
                  <h1 className="font-title text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Page not found
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    Please check the URL in the address bar and try again.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent">
                  <Link
                    to="/"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-brand hover:bg-green-brandHover focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Go back home
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
